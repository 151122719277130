<template>
  <div class="app-container">
    <div class="threejs-background" ref="background"></div>
    <div class="content-container">
      <div class="link-container">
        <h1 class="title">Emmelies socials</h1>
        <ul class="link-list">
          <li v-for="(link, index) in links" :key="index">
            <a href="#" @click.prevent="handleClick(link.url, index, $event)" class="link-button" @mouseenter="startHealthBar" @mouseleave="resetHealthBar">
              {{ link.name }}
              <div class="health-bar"></div>
            </a>
          </li>
        </ul>
      </div>
      <div class="twitch-embed">
        <iframe
          src="https://player.twitch.tv/?channel=emmeetv&parent=itsemmee.com"
          frameborder="0"
          allowfullscreen="true"
          scrolling="no"
          height="100%"
          width="100%">
        </iframe>
      </div>
    </div>
    <div v-if="showPopup" class="popup">
      Hurry and click to kill it!
    </div>
    <div v-if="allButtonsDead" class="victory-message">
      Victory
    </div>
  </div>
</template>


<script>
import * as THREE from 'three';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import tmi from 'tmi.js'; // Import tmi.js to connect to Twitch chat

export default {
  name: 'App',
  data() {
    return {
      links: [
        { name: 'Tiktok', url: 'https://www.tiktok.com/@emmeetv' },
        { name: 'Youtube', url: 'https://www.youtube.com/@EmmeeTV' },
        { name: 'Twitter', url: 'https://x.com/EmmeeTV' },
        { name: 'Twitch', url: 'https://www.twitch.tv/emmeetv' },
      ],
      showPopup: false,
      popupTimeout: null,
      allButtonsDead: false,
      deadLinks: [false, false, false, false], // Initialize with false for each link
      slowMotion: false, // Initialize slowMotion state
      chatUsers: [],
      userTexts: [], // Rename userDots to userTexts
      scene: null,
      camera: null,
      renderer: null,
    };
  },
  mounted() {
    this.init3DScene();
    this.connectTwitchChat(); // Add this function to connect to Twitch chat
  },
  methods: {
    init3DScene() {
      const container = this.$refs.background;
      this.scene = new THREE.Scene();
      this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.5, 5000);
      this.renderer = new THREE.WebGLRenderer({ alpha: true });

      this.renderer.setSize(window.innerWidth, window.innerHeight);
      container.appendChild(this.renderer.domElement);

      const particlesGeometry = new THREE.BufferGeometry();
      const particlesCount = 5000;
      const posArray = new Float32Array(particlesCount * 3);

      for (let i = 0; i < particlesCount * 3; i++) {
        posArray[i] = (Math.random() - 0.5) * 10;
      }

      particlesGeometry.setAttribute('position', new THREE.BufferAttribute(posArray, 3));

      const particlesMaterial = new THREE.PointsMaterial({
        size: 0.005,
        color: 0xffffff,
      });

      const particlesMesh = new THREE.Points(particlesGeometry, particlesMaterial);
      this.scene.add(particlesMesh);

      this.camera.position.z = 5;
      this.camera.position.y = -2;

      const animate = () => {
        requestAnimationFrame(animate);

        particlesMesh.rotation.y += this.slowMotion ? 0.0005 : 0.001; // Adjust slow-motion speed

        // Move user texts randomly
        this.userTexts.forEach((textMesh) => {
          textMesh.position.x += 0.009; // Move to the right
          if (textMesh.position.x > 10) { // Adjust this value based on your scene dimensions
            textMesh.position.x = -10; // Wrap around to the left
          }
        });

        this.renderer.render(this.scene, this.camera);
      };
      animate();

      window.addEventListener('resize', () => {
        const width = window.innerWidth;
        const height = window.innerHeight;
        this.renderer.setSize(width, height);
        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();
      });
    },
    connectTwitchChat() {
      // Initialize tmi.js client
      const client = new tmi.Client({
        channels: ['emmeetv'] // Replace with your Twitch channel name
      });

      client.connect();

      client.on('message', (channel, tags) => {
        const username = tags['display-name'];
        this.addChatUser(username); // Add the chat user to Three.js
      });
    },
    startHealthBar(event) {
      const button = event.target;
      if (button.classList.contains('dead-button')) {
        return; // Do nothing if the button is dead
      }

      const healthBar = button.querySelector('.health-bar');
      healthBar.style.animationPlayState = 'running';

      this.slowMotion = true; // Enable slow motion

      // Show popup when health bar is halfway
      this.popupTimeout = setTimeout(() => {
        this.showPopup = true;
      }, 2500); // 50% of 5s animation duration
    },
    resetHealthBar(event) {
      const healthBar = event.target.querySelector('.health-bar');
      healthBar.style.animation = 'none';
      // Trigger reflow to restart the animation
      healthBar.offsetHeight;
      healthBar.style.animation = '';
      this.showPopup = false;
      clearTimeout(this.popupTimeout);

      this.slowMotion = false; // Disable slow motion
    },
    handleClick(url, index, event) {
      // Hide popup and add a delay before opening the new tab
      this.showPopup = false;
      const button = event.target;
      button.classList.add('dead-button');
      this.deadLinks[index] = true; // Mark the link as dead
      setTimeout(() => {
        window.open(url, '_blank');
        this.checkAllButtonsDead();
      }, 500); // 500ms delay
    },
    checkAllButtonsDead() {
      this.allButtonsDead = this.deadLinks.every(dead => dead);
    },
  
    addChatUser(username) {
      if (!this.chatUsers.includes(username)) {
        this.chatUsers.push(username);
        this.addUserText(username);
      }
    },
    addUserText(username) {
      const fontLoader = new FontLoader();
      fontLoader.load('https://threejs.org/examples/fonts/helvetiker_regular.typeface.json', (font) => {
        const textGeometry = new TextGeometry(username, {
          font: font,
          size: 0.1,
          height: 0.01,
        });
        const textMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });
        const textMesh = new THREE.Mesh(textGeometry, textMaterial);
        textMesh.position.set(
  (Math.random() - 0.5) * 5, // Adjust x position to be within -2.5 to 2.5
  (Math.random() - 0.5) * 2.5, // Adjust y position to be within -1.25 to 1.25
  0 // Z can stay 0 to prevent wobbling in depth
);

        this.scene.add(textMesh);
        this.userTexts.push(textMesh);

        // Remove the text after 2 minutes (120000 milliseconds)
        setTimeout(() => {
          this.scene.remove(textMesh);
          this.userTexts = this.userTexts.filter(text => text !== textMesh);
        }, 120000);
      });
    },
  },
};
</script>

<style scoped>
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  overflow: hidden;
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  position: relative;
  background-color: #1a1a1a;
  overflow: hidden;
}

.threejs-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0; /* Background */
}

.content-container {
  z-index: 1; /* Foreground */
}

.link-container {
  text-align: center;
}

.title {
  font-size: 2.5rem;
  color: #ff0000;
  margin-bottom: 1rem;
}

.link-list {
  list-style-type: none;
  padding: 0;
}

.link-button {
  display: block;
  background-color: #ff0000;
  color: white;
  padding: 1rem;
  margin: 0.5rem 0;
  text-decoration: none;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

.link-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 69, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: -1;
}

.link-button:hover {
  transform: scale(1.05);
  box-shadow: 0 0 15px rgba(255, 0, 0, 0.7);
}

.link-button:hover::before {
  opacity: 1;
  animation: fire 1s infinite;
}

.link-button:active {
  transform: scale(0.95) rotate(2deg);
  animation: attack 0.1s 3;
}

.link-button .health-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: green;
  animation: decrease-health 8s linear forwards;
  animation-play-state: paused;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.link-button:hover .health-bar {
  opacity: 1;
  animation-play-state: running;
}

.dead-button {
  background-color: grey;
  color: darkgrey;
  text-decoration: line-through;
  box-shadow: none;
  transform: none;
  animation: none;
  cursor: not-allowed;
  background: linear-gradient(135deg, #808080 25%, transparent 25%) -50px 0,
              linear-gradient(225deg, #808080 25%, transparent 25%) -50px 0,
              linear-gradient(315deg, #808080 25%, transparent 25%),
              linear-gradient(45deg, #808080 25%, transparent 25%);
  background-size: 100px 100px;
  background-color: grey;
}

.dead-button::before {
  background: none;
}

.dead-button .health-bar {
  display: none;
}

.popup {
  position: fixed;
  top: 50%;
  right: 25%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  z-index: 2;
  animation: fadeIn 0.5s ease-in-out;
  white-space: nowrap;
  font-family: 'Comic Sans MS', cursive, sans-serif;
  font-size: 1rem;
  border: 2px solid white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.victory-message {
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 3rem;
  color: green;
  z-index: 3;
}

@keyframes fire {
  0% { background: rgba(255, 69, 0, 0.5); }
  50% { background: rgba(255, 140, 0, 0.5); }
  100% { background: rgba(255, 69, 0, 0.5); }
}

@keyframes attack {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
}

@keyframes decrease-health {
  0% { width: 100%; background-color: green; }
  50% { width: 50%; background-color: yellow; }
  100% { width: 0%; background-color: red; }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.twitch-embed {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 300px;
  height: 169px;
  border-radius: 10px;
  overflow: hidden;
}
</style>
